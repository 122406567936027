<template>
  <Paginator
    :rows="limit"
    :totalRecords="_useHistorial.count"
    :rowsPerPageOptions="_useHistorial.pageOptions"
    :pageLinkSize="1"
    @page="onPage"
     />
</template>
<script setup>
import { computed, onMounted } from 'vue'
import { useHistorialStore } from '../../../store/historial.store'
const _useHistorial = useHistorialStore()
const limit = computed(() => _useHistorial._limit)
onMounted(() => {
  _useHistorial.listar()
})
const onPage = (e) => {
  _useHistorial.offset = e.first
  _useHistorial.listar()
}
</script>
